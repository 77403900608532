// extracted by mini-css-extract-plugin
export var featureBox = "Desk-Room-Booking-Software-module--featureBox--335f9";
export var featureContainer = "Desk-Room-Booking-Software-module--featureContainer--59be1";
export var featureDesc = "Desk-Room-Booking-Software-module--featureDesc--0ae1b";
export var featureGridCompWrapper = "Desk-Room-Booking-Software-module--featureGridCompWrapper--006fe";
export var featureGridDesc = "Desk-Room-Booking-Software-module--featureGridDesc--c3a52";
export var featureGridTitle = "Desk-Room-Booking-Software-module--featureGridTitle--5b6ba";
export var featureGridWrapper = "Desk-Room-Booking-Software-module--featureGridWrapper--b8adb";
export var featureHeadingWrapper = "Desk-Room-Booking-Software-module--featureHeadingWrapper--6534c";
export var featureLogoWrapper = "Desk-Room-Booking-Software-module--featureLogoWrapper--12ce8";
export var featureProductsLogo = "Desk-Room-Booking-Software-module--featureProductsLogo--f0144";
export var featureProductsLogoList = "Desk-Room-Booking-Software-module--featureProductsLogoList--e0888";
export var featureProductsLogoWrapper = "Desk-Room-Booking-Software-module--featureProductsLogoWrapper--98b3d";
export var featureProductsText = "Desk-Room-Booking-Software-module--featureProductsText--d964c";
export var featureProductsWrapper = "Desk-Room-Booking-Software-module--featureProductsWrapper--dca1e";
export var featureTitle = "Desk-Room-Booking-Software-module--featureTitle--c3bcf";
export var featureWrapper = "Desk-Room-Booking-Software-module--featureWrapper--154ca";
export var fullWidthBannerContainer = "Desk-Room-Booking-Software-module--fullWidthBannerContainer--face0";
export var fullWidthBannerDesc = "Desk-Room-Booking-Software-module--fullWidthBannerDesc--afa63";
export var fullWidthBannerImage = "Desk-Room-Booking-Software-module--fullWidthBannerImage--12572";
export var fullWidthBannerLogo = "Desk-Room-Booking-Software-module--fullWidthBannerLogo--0ec57";
export var fullWidthBannerLogoWrapper = "Desk-Room-Booking-Software-module--fullWidthBannerLogoWrapper--6fb64";
export var fullWidthBannerName = "Desk-Room-Booking-Software-module--fullWidthBannerName--f4f11";
export var fullWidthBannerTextWrapper = "Desk-Room-Booking-Software-module--fullWidthBannerTextWrapper--47f7a";
export var fullWidthBannerTitle = "Desk-Room-Booking-Software-module--fullWidthBannerTitle--8e0af";
export var fullWidthBannerWrapper = "Desk-Room-Booking-Software-module--fullWidthBannerWrapper--0920e";
export var hero1Desc = "Desk-Room-Booking-Software-module--hero1Desc--8718f";
export var hero1Title = "Desk-Room-Booking-Software-module--hero1Title--7e62e";
export var heroBtn1 = "Desk-Room-Booking-Software-module--heroBtn1--34b8d";
export var heroBtn1Img = "Desk-Room-Booking-Software-module--heroBtn1Img--34cc7";
export var heroBtn1Text = "Desk-Room-Booking-Software-module--heroBtn1Text--229b3";
export var heroBtn1Wrapper = "Desk-Room-Booking-Software-module--heroBtn1Wrapper--50a8d";
export var heroBtn2Text = "Desk-Room-Booking-Software-module--heroBtn2Text--fe524";
export var heroBtn2Wrapper = "Desk-Room-Booking-Software-module--heroBtn2Wrapper--e5b2e";
export var heroBtnWrapper = "Desk-Room-Booking-Software-module--heroBtnWrapper--03658";
export var heroContainer = "Desk-Room-Booking-Software-module--heroContainer--f55d8";
export var heroContainer1 = "Desk-Room-Booking-Software-module--heroContainer1--43be5";
export var heroDesc = "Desk-Room-Booking-Software-module--heroDesc--58f5d";
export var heroImage = "Desk-Room-Booking-Software-module--heroImage--ef39b";
export var heroTitle = "Desk-Room-Booking-Software-module--heroTitle--08737";
export var leftWrapper = "Desk-Room-Booking-Software-module--leftWrapper--b9c16";
export var logoSliderContainer = "Desk-Room-Booking-Software-module--logoSliderContainer--772a8";
export var logoSliderImage = "Desk-Room-Booking-Software-module--logoSliderImage--838a4";
export var logoStrip = "Desk-Room-Booking-Software-module--logoStrip--cd1d9";
export var resourceCard = "Desk-Room-Booking-Software-module--resourceCard--e071d";
export var resourceCardBtn = "Desk-Room-Booking-Software-module--resourceCardBtn--ab4ab";
export var resourceCardBtnWrapper = "Desk-Room-Booking-Software-module--resourceCardBtnWrapper--6b68d";
export var resourceCardImg = "Desk-Room-Booking-Software-module--resourceCardImg--47465";
export var resourceCardTextWrapper = "Desk-Room-Booking-Software-module--resourceCardTextWrapper--e7ebd";
export var resourceCardTitle = "Desk-Room-Booking-Software-module--resourceCardTitle--b6019";
export var resourceCardWrapper = "Desk-Room-Booking-Software-module--resourceCardWrapper--3df8f";
export var resourceContainer = "Desk-Room-Booking-Software-module--resourceContainer--943e6";
export var resourceHeadingWrapper = "Desk-Room-Booking-Software-module--resourceHeadingWrapper--46b41";
export var resourceLinkText = "Desk-Room-Booking-Software-module--resourceLinkText--d0083";
export var resourceLinkWrapper = "Desk-Room-Booking-Software-module--resourceLinkWrapper--9d97a";
export var resourceListCardBig = "Desk-Room-Booking-Software-module--resourceListCardBig--d7865";
export var resourceMultiCard = "Desk-Room-Booking-Software-module--resourceMultiCard--26210";
export var resourceMultiCardDesc = "Desk-Room-Booking-Software-module--resourceMultiCardDesc--15996";
export var resourceMultiCardImg = "Desk-Room-Booking-Software-module--resourceMultiCardImg--d8c4d";
export var resourceMultiCardText = "Desk-Room-Booking-Software-module--resourceMultiCardText--9e7ad";
export var resourceMultiCardTextWrapper = "Desk-Room-Booking-Software-module--resourceMultiCardTextWrapper--fba64";
export var resourceMultiCardWrapper = "Desk-Room-Booking-Software-module--resourceMultiCardWrapper--83c7d";
export var resourceTitle = "Desk-Room-Booking-Software-module--resourceTitle--4a1b8";
export var resourceWrapper = "Desk-Room-Booking-Software-module--resourceWrapper--c2e60";
export var serviceCardContainer = "Desk-Room-Booking-Software-module--serviceCardContainer--c2d2c";
export var serviceCardDesc = "Desk-Room-Booking-Software-module--serviceCardDesc--b5070";
export var serviceCardLinkText = "Desk-Room-Booking-Software-module--serviceCardLinkText--fc5a3";
export var serviceCardLinkWrapper = "Desk-Room-Booking-Software-module--serviceCardLinkWrapper--71580";
export var serviceCardLogo = "Desk-Room-Booking-Software-module--serviceCardLogo--5b3f9";
export var serviceCardLogoWrapper = "Desk-Room-Booking-Software-module--serviceCardLogoWrapper--287fe";
export var serviceCardText = "Desk-Room-Booking-Software-module--serviceCardText--9fc23";
export var serviceCardTextWrapper = "Desk-Room-Booking-Software-module--serviceCardTextWrapper--a5d33";
export var serviceCardTitle = "Desk-Room-Booking-Software-module--serviceCardTitle--96636";
export var serviceCardWrapper = "Desk-Room-Booking-Software-module--serviceCardWrapper--7550a";
export var serviceContainer = "Desk-Room-Booking-Software-module--serviceContainer--12510";
export var serviceDesc = "Desk-Room-Booking-Software-module--serviceDesc--1679b";
export var serviceHeadWrapper = "Desk-Room-Booking-Software-module--serviceHeadWrapper--e1c05";
export var serviceTitle = "Desk-Room-Booking-Software-module--serviceTitle--2318f";
export var supportContactLink = "Desk-Room-Booking-Software-module--supportContactLink--4bdf6";
export var supportContactLinkText = "Desk-Room-Booking-Software-module--supportContactLinkText--0b429";
export var supportContactText = "Desk-Room-Booking-Software-module--supportContactText--a029c";
export var supportContactWrapper = "Desk-Room-Booking-Software-module--supportContactWrapper--c86c5";
export var supportContainer = "Desk-Room-Booking-Software-module--supportContainer--3c2cb";
export var supportHeadWrapper = "Desk-Room-Booking-Software-module--supportHeadWrapper--a7da0";
export var supportListDesc = "Desk-Room-Booking-Software-module--supportListDesc--242f6";
export var supportListDescWrapper = "Desk-Room-Booking-Software-module--supportListDescWrapper--1a793";
export var supportListTitle = "Desk-Room-Booking-Software-module--supportListTitle--92f28";
export var supportListTitleWrapper = "Desk-Room-Booking-Software-module--supportListTitleWrapper--80991";
export var supportListWrapper = "Desk-Room-Booking-Software-module--supportListWrapper--9ddc8";
export var supportQListWrapper = "Desk-Room-Booking-Software-module--supportQListWrapper--dc4eb";
export var supportTitle = "Desk-Room-Booking-Software-module--supportTitle--1fdb7";
export var threeColContainer = "Desk-Room-Booking-Software-module--threeColContainer--4cd24";
export var threeColDesc = "Desk-Room-Booking-Software-module--threeColDesc--b2b86";
export var threeColGrid = "Desk-Room-Booking-Software-module--threeColGrid--1478d";
export var threeColGridBox = "Desk-Room-Booking-Software-module--threeColGridBox--45cec";
export var threeColGridDesc = "Desk-Room-Booking-Software-module--threeColGridDesc--9a80a";
export var threeColGridTitle = "Desk-Room-Booking-Software-module--threeColGridTitle--f9c5c";
export var threeColGridWrapper = "Desk-Room-Booking-Software-module--threeColGridWrapper--08741";
export var threeColHeadWrapper = "Desk-Room-Booking-Software-module--threeColHeadWrapper--26ba8";
export var threeColLogoWrapper = "Desk-Room-Booking-Software-module--threeColLogoWrapper--b5720";
export var threeColTitle = "Desk-Room-Booking-Software-module--threeColTitle--5703e";
export var twoColCompCardDesc = "Desk-Room-Booking-Software-module--twoColCompCardDesc--2aed6";
export var twoColCompCardLink = "Desk-Room-Booking-Software-module--twoColCompCardLink--de4b5";
export var twoColCompCardLinkText = "Desk-Room-Booking-Software-module--twoColCompCardLinkText--b1896";
export var twoColCompCardLogoWrapper = "Desk-Room-Booking-Software-module--twoColCompCardLogoWrapper--f616d";
export var twoColCompCardTextWrapper = "Desk-Room-Booking-Software-module--twoColCompCardTextWrapper--4a05b";
export var twoColCompCardTitle = "Desk-Room-Booking-Software-module--twoColCompCardTitle--90815";
export var twoColCompCardWrapper = "Desk-Room-Booking-Software-module--twoColCompCardWrapper--983e9";
export var twoColCompContainer = "Desk-Room-Booking-Software-module--twoColCompContainer--c22f8";
export var twoColCompDesc = "Desk-Room-Booking-Software-module--twoColCompDesc--080d6";
export var twoColCompLeftWrapper = "Desk-Room-Booking-Software-module--twoColCompLeftWrapper--c211f";
export var twoColCompLinkText = "Desk-Room-Booking-Software-module--twoColCompLinkText--ed647";
export var twoColCompLinkWrapper = "Desk-Room-Booking-Software-module--twoColCompLinkWrapper--5d6f1";
export var twoColCompRightWrapper = "Desk-Room-Booking-Software-module--twoColCompRightWrapper--46ac1";
export var twoColCompTextWrapper = "Desk-Room-Booking-Software-module--twoColCompTextWrapper--81b05";
export var twoColCompTitle = "Desk-Room-Booking-Software-module--twoColCompTitle--2a830";
export var twoColContainer = "Desk-Room-Booking-Software-module--twoColContainer--a01d0";
export var twoColGridLink = "Desk-Room-Booking-Software-module--twoColGridLink--85b4c";
export var twoColGridWrapper = "Desk-Room-Booking-Software-module--twoColGridWrapper--49fc7";
export var twoColGriditemBox = "Desk-Room-Booking-Software-module--twoColGriditemBox--5248c";
export var twoColTitle = "Desk-Room-Booking-Software-module--twoColTitle--6aea0";
export var twocColGridContent = "Desk-Room-Booking-Software-module--twocColGridContent--e9a9c";
export var twocColGridContentWrapper = "Desk-Room-Booking-Software-module--twocColGridContentWrapper--3293c";
export var twocColGridDesc = "Desk-Room-Booking-Software-module--twocColGridDesc--e276b";
export var twocColGridDescList = "Desk-Room-Booking-Software-module--twocColGridDescList--f9162";
export var twocColGridImage = "Desk-Room-Booking-Software-module--twocColGridImage--1c262";
export var twocColGridTitle = "Desk-Room-Booking-Software-module--twocColGridTitle--261e0";
export var walkthroughCardDesc = "Desk-Room-Booking-Software-module--walkthroughCardDesc--9a380";
export var walkthroughCardText = "Desk-Room-Booking-Software-module--walkthroughCardText--1ef95";
export var walkthroughCardTitle = "Desk-Room-Booking-Software-module--walkthroughCardTitle--0edf2";
export var walkthroughCardWrapper = "Desk-Room-Booking-Software-module--walkthroughCardWrapper--32c64";
export var walkthroughFlexWrapper = "Desk-Room-Booking-Software-module--walkthroughFlexWrapper--00dd2";
export var walkthroughTitle = "Desk-Room-Booking-Software-module--walkthroughTitle--1da61";
export var walkthroughWrapper = "Desk-Room-Booking-Software-module--walkthroughWrapper--4ca03";