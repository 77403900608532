import React, {useState} from "react";
import * as styles from "src/styles/Desk-Room-Booking-Software.module.css";
import { SEO, Picture } from "src/components/commonComponents";
import Modal from 'react-modal';
import Dot from "../../../static/staticPages/Dot.svg"
import Play1 from "../../../static/staticPages/Play1.svg"
import arrowRight from "../../../static/staticPages/arrowRight.svg"
import solutionDeskRoomHeroImage from "../../../static/staticPages/desk-hero.jpg"

import featureLogo from "../../../static/staticPages/featureLogo.svg"
import mobileLogo from "../../../static/staticPages/drb-icons/laptop-mobile-solid.svg";
import usersLogo from "../../../static/staticPages/drb-icons/users-solid.svg";
import houseLaptop from "../../../static/staticPages/drb-icons/house-laptop-solid.svg";
import linkLogo from "../../../static/staticPages/drb-icons/link-solid.svg";
import fileChart from "../../../static/staticPages/drb-icons/file-chart-pie-solid.svg";
import tableColumn from "../../../static/staticPages/drb-icons/table-columns-solid.svg";

import featureProduct1 from "../../../static/staticPages/featureProduct1.svg"
import featureProduct2 from "../../../static/staticPages/featureProduct2.svg"
import fullWidthBannerLogoDesk from "../../../static/staticPages/fullWidthBannerLogoDesk.jpeg"

import graduationCap from "../../../static/staticPages/drb-icons/graduation-cap-solid.svg";
import heartPulse from "../../../static/staticPages/drb-icons/heart-pulse-solid.svg";
import building from "../../../static/staticPages/drb-icons/buildings-solid.svg";


import logo1 from "../../../static/staticPages/logo1.svg"
import logo2 from "../../../static/staticPages/logo2.svg"
import logo3 from "../../../static/staticPages/logo3.svg"
import logo4 from "../../../static/staticPages/logo4.svg"
import logo5 from "../../../static/staticPages/logo5.svg"
import logo6 from "../../../static/staticPages/logo6.svg"

import dukeLogo from "../../../static/staticPages/drb-icons/customers/Duke_logo.png";
import minnesotta from "../../../static/staticPages/drb-icons/customers/Minnesota-State-logo.png";
import essex from "../../../static/staticPages/drb-icons/customers/ecc_logo.png";
import notre from "../../../static/staticPages/drb-icons/customers/university_notre_dame_logo_customer_quote.png";
import babson from "../../../static/staticPages/drb-icons/customers/babsoncollege-logo.png";
import babsoncollegeLogo from "../../../static/staticPages/babsoncollege-logo.png"


import arrowRightBlue from "../../../static/staticPages/arrowRightBlue.svg"
import spaceHero2 from "../../../static/staticPages/space-hero2.png"
import service1 from "../../../static/staticPages/ems-logo.svg"
import service2 from "../../../static/staticPages/lucernex-logo.svg"
import service3 from "../../../static/staticPages/service3.svg"
import service4 from "../../../static/staticPages/service4.svg"
import redeyeLogo from "../../../static/staticPages/redeye_logo.svg"
import resource1 from "../../../static/staticPages/resource_1_desk.jpeg"
import resource2 from "../../../static/staticPages/resource_2_desk.jpeg"
import resource3 from "../../../static/staticPages/resource_3_desk.jpeg"
import resource4 from "../../../static/staticPages/resource_3_desk.jpeg"
import resource5 from "../../../static/staticPages/drb-icons/spacemanagement-hero.jpg"
import arrowDownBlue from "../../../static/staticPages/arrowDownBlue.svg"
import arrowUpBlue from "../../../static/staticPages/arrowUpBlue.svg"
import { useHeroImages } from "src/hooks/useHeroImages";
import { getPageDataJsonPath } from "src/utils";
import { useFormImages } from "src/hooks/useFormImages";
import { ContentMarketoForm } from "src/components/commonComponents/forms/ContentMarketoForm";

const data = {
  heroBanner:{
    title: 'Desk and Room Booking Software',
    description:'Enable your teams to seamlessly find and book the spaces they need using our flexible, enterprise-level desk and room booking software.',
    link1: {
      url: '/request-demo',
      text: 'Request A Demo'
    },
    link2: {
      url: '/contactus',
      text: 'Contact Sales'
    },
    image: {
      url: solutionDeskRoomHeroImage,
      altText: 'Employee using desk and room booking software to connect with colleagues in an office setting.'
    }
  },
  features: {
    title: 'Desk and Room Scheduling Made Easy',
    description: 'Streamline self-service desk and room booking, mitigate scheduling conflicts, adapt to workplace changes, and improving space use and compliance',
    items: [
      {
        logoUrl: mobileLogo,
        altText: "Laptop icon representing the benefit",
        title: "Simplify Room Booking",
        description: "Easily reserve, modify, or cancel desk and room bookings whenever  you want from your computer, phone, tablet, or kiosks -- all intgrated with your calendar solution.",
      },
      {
        logoUrl: usersLogo,
        altText: "Users icon representing the benefit",
        title: "Enable Thoughtful Collaboration",
        description: "Foster innovation and collaboration by sitting in designated neighborhoods or allowing flexible self-service based on space type, equipment, nearby amenities, and more.",
      },
      {
        logoUrl: houseLaptop,
        altText: "House laptop representing the benefit",
        title: "Streamline Hybrid Work",
        description: "Give your hybrid, remote, and in-person employees easy access to the spaces and equipment they need to work  and collaborate efficiently."
      },
      {
        logoUrl: linkLogo,
        altText: "Link icon representing the benefit",
        title: "Work With Your Existing Tech Stack",
        description: "Working with a SIS, Microsoft Outlook, Microsoft Exchange, Zoom, Skype, or other popular apps and systems? EMS integrates with all your favorites in a single, flexible interface."
      },
      {
        logoUrl: fileChart,
        altText: "Pie chart icon representing the benefit",
        title: "Maximize Insight With Powerful Reporting",
        description: "Get instant visibility into meaningful usage analytics like where employees are sitting, which spaces are underutilized, and where you can make better space and lease decisions. "
      },
      {
        logoUrl: tableColumn,
        altText: "Table icon representing the benefit",
        title: "Automate the Booking Experience",
        description: "Receive automated confirmations, change notifications, space alerts, and real-time requests. Make easy changes and updates whenever necessary."
      }
    ],
    info:{
      title: "Software Built to Optimize Your Desk and Room Booking",
      images:[
      // {
      //   url: featureProduct1,
      //   altText:"Accruent Lucernex logo",
      //   link:'/products/lucernex'
      // },
      {
        url: featureProduct2,
        altText:"Accruent EMS logo",
        link:'/products/ems'
      }
    ],
    link: {
      text: 'Request a demo',
      url:'/request-demo'
    }
    }
  },
  fullWidthBanner:{
    image: {
      url:babsoncollegeLogo,
      altText:"Babson College logo"
    },
    title:"Director of Campus and Community Events, Babson College",
    description: "The other components I would mention just about EMS in general is that it has endless opportunities... I've heard in some sessions where someone would say 'I wish it could do that' and a few of us would say 'It does do that!' [...] There's so many different opportunities that EMS offers that...are untapped, and I would advocate, if people have the funds, to go through a business process review because it's really interesting to discover what things can get done, especially from reporting and data.",
    name: "Therese Doyla",
    position: "Director of Campus and Community Events",
    // companyName: "Babson College"
  },
  logoSlider:{
    logoList:[
      {
        url: dukeLogo,
        altText:'Duke University Logo'
      },
      {
        url: minnesotta,
        altText:'Minnesota State College and Universities logo'
      },
      {
        url: essex,
        altText:'Essex County Council logo'
      },
      {
        url: notre,
        altText:'University of Notre Dame logo'
      },
      {
        url: babson,
        altText:'Babson College logo'
      }
    ]
  },
  twoColComp:{
    title: "Stay ahead with powerful desk and space booking capabilities",
    description: "Check out how EMS' powerful features can help your team eliminate space and resource scheduling headaches",
    link: {
      url: '/industries',
      text: "Explore industries using Accruent software"
    },
    items:[
      {
        logo: {
          url: graduationCap,
          alt:'Graduation cap icon representing the education industry'
        },
        title: "Education",
        description: "Accruent's software streamlines desk and room booking for over 40% of leading North American universities, enhancing campus facility readiness and mission support with its proven desk and room booking capabilities.",
        link: {
          url: '/industries/education',
          text:'Explore Education'
        }
      },
      {
        logo: {
          url: building,
          alt:'Building representing Corporate'
        },
        title: "Corporate",
        description: "Accruent's streamlines the desk and room booking process, enhancing  corporate real estate and driving ROI through integrated data insights and efficiency.",
        link: {
          url: '/industries/corporate',
          text:'Explore Corporate'
        }
      }
    ]
  },
  heroBanner1:{
    title: 'Help your teams seamlessly find and book the spaces they need using our flexible, enterprise-level desk and room booking software.',
    description:'Access a live one-on-one demo with an Accruent representative, today.',
    link1: {
      url: '/request-demo',
      text: 'Request a demo'
    },
    link2: {
      url: '/contactus',
      text: 'Contact Sales'
    },
    image: {
      url: spaceHero2,
      alt: 'Manager using advanced meeting room and desk booking software.'
    }
  },
  services: {
    title: "Products designed to integrate your world",
    description: "Accruent software allows you to fully optimize the lifecycle of your asset and facilities, offering connected workflows, integrated experiences and data-backed insights.",
    items:[
      {
        logo:{
          url: service1,
          alt: "Alt"
        },
        title: "Desk, Room and Event Scheduling Made Easy",
        description:"Workplace management software simplifies desk, meeting and resource scheduling for your office or campus.",
        link:{
          url: '/products/ems',
          text: "Explore EMS"
        }
      },
      {
        logo:{
          url: service2,
          alt: "Alt"
        },
        title: "Real Estate Management Software",
        description:"Market-leading real estate software solutions manage lease, site planning and construction complexities.",
        link:{
          url: '/products/lucernex',
          text: "Explore Lucernex"
        }
      },
      {
        logo:{
          url: redeyeLogo,
          alt: "Alt"
        },
        title: "Engineering Document Management Made Easy",
        description:"Engineering document management system streamlines compliance and maximizes compay-wide collaboration.",
        link:{
          url: '/products/redeye',
          text: "Explore RedEye"
        }
      },
      {
        logo:{
          url: service4,
          alt: "Alt"
        },
        title: "Multi-site, multi-industry CMMS software",
        description:"CMMS software reduces downtime, predicts maintenance needs and managers asset and equipment inventory.",
        link:{
          url: '/products/maintenance-connection',
          text: "Explore Maintenance Connection"
        }
      }
    ]
  },
  resources: {
    title: "Some resources you might find interesting",
    link:{
      url:'/resources',
      text:"Explore Accruent resources"
    },
    resourceList:{
      resource1: {
        image:{
          url: resource1,
          altText: 'Duke University Uses EMS Enterprise for Campus-Wide Optimizations'
        },
        title:"Duke University Uses EMS Enterprise for Campus-Wide Optimizations",
        description:"Hear from Duke University's Ryan Cakerice about the game-changing switch from EMS Professional to the EMS Enterprise solution.",
        link: '/resources/case-studies/duke-university-uses-ems-enterprise-campus-wide-optimizations',
        button:{
          text: 'Case Study',
        }
      },
      resource2: {
        image:{
          url: resource2,
          altText: 'Streamlined Workspace Booking for Higher Employee Engagement'
        },
        title:"Streamlined Workspace Booking for Higher Employee Engagement",
        description:"Workspace booking software can mean the difference between a great and employee experience ― and a poor one. Learn how modern desk booking software increases employee engagement and optimizes the hybrid office.",
        link: '/resources/ebooks/streamlined-workspace-booking-employee-engagement',
        button:{
          text: 'E-book',
        }
      },
      list:[
        {
          image:{
            url: resource3,
            altText: 'A Checklist for Selecting the Right Scheduling Platform for Your Organization'
          },
          title:"A Checklist for Selecting the Right Scheduling Platform for Your Organization",
          link: '/resources/white-papers/checklist-selecting-right-scheduling-platform-your-organization',
          button:{
            text: 'Whitepaper',
          }
        },
        {
          image:{
            url: resource4,
            altText: 'Reshaping the Modern Workplace: The Future of Work is Now'
          },
          title:"Reshaping the Modern Workplace: The Future of Work is Now",
          link: '/resources/webinars/reshaping-modern-workplace-future-work-now',
          button:{
            text: 'Webinar',
          }
        },
        {
          image:{
            url: resource5,
            altText: "Optimizing Your Company's Space and Facility Management in 2024 and Beyond"
          },
          title:"Optimizing Your Company's Space and Facility Management in 2024 and Beyond",
          link: '/resources/white-papers/optimizing-your-companys-space-and-facility-management-2024-and-beyond',
          button:{
            text: 'Whitepaper',
          }
        }
      ]
    },
    walkthrough: {
      title:"Walkthrough demonstrations",
      items:[
        {
          logo:{
            url: Play1,
            altText: 'Alt text'
          },
          title:"EMS Meeting and Room Scheduling",
          description: "See how EMS room scheduling solution can help you find the right spaces and inviting the right people",
          link : 'https://accruent.wistia.com/medias/hgbgp4tj44',
          wistiaId: 'hgbgp4tj44'
        },
        {
          logo:{
            url: Play1,
            altText: 'Alt text'
          },
          title:"EMS: Booking a Room",
          description: "Learn how to leverage EMS software to quickly find and book a room that addresses all your meeting needs",
          link : 'https://accruent.wistia.com/medias/wl3c9vjdjn',
          wistiaId: 'wl3c9vjdjn'
        },
        {
          logo:{
            url: Play1,
            altText: 'Alt text'
          },
          title:"EMS: Custom Reports",
          description: "See how to leverage Query Builder to create your own custom reports",
          link : 'https://accruent.wistia.com/medias/fik44mjmz1',
          wistiaId: 'fik44mjmz1'
        },
        {
          logo:{
            url: Play1,
            altText: 'Alt text'
          },
          title:"EMS: Video Integration",
          description: "Empower employees, students, visitors, and customers with convenient, fully integrated video conference scheduling",
          link : 'https://accruent.wistia.com/medias/wze1xeez3i',
          wistiaId: 'wze1xeez3i'
        }
      ]
    }
  },
  support:{
    logo:{
      url: featureProduct2,
      altText: 'Design Logo'
    },
    title: 'Frequently Asked Questions',
    list: [
      {
        title: 'How does desk and room booking software support flexible work environments? ',
        descList: [
          {
            text: '<span>With the global migration to <a href="https://www.emssoftware.com/resources/blog-posts/future-flexible-supporting-hybrid-workforce" style="text-decoration-line:underline">remote or hybrid work arrangements</a>, desk and room booking software is now more important than ever to maintain organizational cohesion.</span>'
          },
          {
            text: '<span>Using effective desk and room booking software, workers can reserve desk space on the days they will be in the office and allow other team members to book those spaces when they are remote. This allows teams to make more efficient use of limited space and removes any confusion around seating.</span>'
          },
          {
            text: '<span>No need for old school seating charts, either! Desk and room booking software provides a virtual view of any office floor, allowing managers to keep track of who is in the office on any given day.</span>'
          }
        ]
      },
      {
        title: 'Is desk and room booking software suitable for different types of organizations?',
        descList: [
          {
            text: '<span>Yes, desk and room booking software can provide value for any organization that needs help arranging a space.</span>'
          },
          {
            text: '<span>Examples range from large enterprise companies with multiple floor office setups or even small college classrooms. Regardless of purpose, this software works well for organizations that are trying to create order within their physical spaces.</span>'
          },
          {
            text: '<span>It does not matter if workers are 100% in the office or remote, there’s value in simplifying the desk booking process.</span>'
          }
        ]
      },
      {
        title: 'Is it easy to implement and begin using?',
        descList: [
          {
            text: '<span>Accruent’s desk and room booking software can be adopted and utilized seamlessly from day one.</span>'
          },
          {
            text: "<span>Once you have uploaded organizational details and space information, you can start having your user's login and <a href='https://www.emssoftware.com/resources/blog-posts/future-flexible-supporting-hybrid-workforce' style='text-decoration-line:underline'>create bookings</a>.</span>"
          },
          {
            text: '<span>And, if you are expanding to a new location that has limited meeting spaces, you can get the software up and running immediately so team members can start adding their bookings to the calendar.</span>'
          }
        ]
      },
      {
        title: 'Does this desk and room booking software integrate with other facility management tools?',
        descList: [
          {
            text: '<span>Yes, this desk and room booking software can easily integrate with other facility-related tools from Accruent. Say hello to your one-stop shop for booking, maintenance, management, and other critical organizational tasks.</span>'
          }
        ]
      },
      {
        title: 'How does desk and room booking software boost employee well-being? ',
        descList: [
          {
            text: '<span>Employees will be happier and more productive knowing that they will have more frictionless experiences booking spaces and reserving rooms.</span>'
          },
          {
            text: '<span>And this is more important than you might think. Top talent wants to be in control of their work situation and able to adjust their schedules with short notice. Let’s say a manager plans a last-minute trip to come to town for a team meeting and wants to ensure they have a quiet, intimate space for discussions. They would want to have a confirmed booking awaiting them, which can be made in seconds using Accruent.</span>'
          }
        ]
      }

    ],
    contact: {
      title: 'Don’t see your question? Let us help you',
      link: {
        url:'/contactus',
        text:'Contact support'
      }
    }
  },
}

const HeroBanner = (heroImages) => {
  return <>
     <div className={styles.heroContainer}>
        <div className={styles.leftWrapper}>
          <h1 className={styles.heroTitle}>
            {data?.heroBanner?.title}
          </h1>
          <p className={styles.heroDesc}>{data?.heroBanner?.description}</p>
          <div className={styles.heroBtnWrapper}>
            <a  href={'#mktoForm'} className={styles.heroBtn1Wrapper}>
              <span className={styles.heroBtn1Text}>{data?.heroBanner?.link1?.text}</span>
              <div className={styles.heroBtn1}>
                <img className={styles.heroBtn1Img} src={Dot}></img>
              </div>
            </a>
            <a href={data?.heroBanner?.link2?.url} className={styles.heroBtn2Wrapper}>
              <span className={styles.heroBtn2Text}>{data?.heroBanner?.link2?.text}</span>
              <img src={arrowRight}></img>
            </a>
        </div>
        </div>
        <div>
        <Picture
            className={styles.heroImage}
            image={heroImages.heroImages['staticPages/desk-hero.jpg']}
            rel="preload"
            alt={data?.heroBanner?.image?.altText}
          />
        </div>
     </div>
  </>
}

const Question = ({item}) => {
  const [open, setOpen] = useState(false)
  return (
    <div className={styles.supportListWrapper}>
      <div onClick={()=>setOpen(!open)} className={styles.supportListTitleWrapper}>
        <img src={open ? arrowUpBlue : arrowDownBlue} alt='arrow'></img>
        <h3 className={styles.supportListTitle}>{item?.title}</h3>
      </div>
      {open && <div className={styles.supportListDescWrapper}>
          {
            item?.descList?.map((i, index)=>
              <p key={index} className={styles.supportListDesc} dangerouslySetInnerHTML={{ __html: i?.text }}>
              </p>
            )
          }
        </div>
      }
    </div>
  )
}

const Support = () => {
  return (
    <div className={styles.supportContainer}>
      <div className={styles.supportHeadWrapper}>
        {/* <img className={styles.supportLogo} src={data?.support?.logo?.url} alt={data?.support?.logo?.altText}></img> */}
        <h2 className={styles.supportTitle}>{data?.support?.title}</h2>
        <div className={styles.supportQListWrapper}>
        {
          data?.support?.list?.map((item, index)=>
            <Question key={index} item={item}/>
          )
        }
      </div>
      </div>
      <div className={styles.supportContactWrapper}>
        <p className={styles.supportContactText}>{data?.support?.contact?.title}</p>
        <a className={styles.supportContactLink} href={data?.support?.contact?.link?.url}>
          <span className={styles.supportContactLinkText}>{data?.support?.contact?.link?.text}</span>
          <img src={arrowRightBlue} alt='Right arrow'></img>
        </a>
      </div>
    </div>
  )
}

const Features = () => {
  return (
    <div className={styles.featureContainer}>
      <div className={styles.featureGridCompWrapper}>
        <div className={styles.featureHeadingWrapper}>
          <h2 className={styles.featureTitle}>{data?.features?.title}</h2>
          <p className={styles.featureDesc}>{data?.features?.description}</p>
        </div>
        <div className={styles.featureGridWrapper}>
          {
            data?.features?.items?.map((item,index)=>
              <div key={index} className={styles.featureBox}>
                <div className={styles.featureWrapper}>
                  <div className={styles.featureLogoWrapper}>
                    <img className=" tw-min-h-[25px]" src={item?.logoUrl} alt={item?.altText} loading="lazy"></img>
                  </div>
                  <h3 className={styles.featureGridTitle}>
                    {item?.title}
                  </h3>
                  <p className={styles.featureGridDesc}>{item?.description}</p>
                </div>
              </div>
            )
            
          }
        </div>       
      </div>
      <div className={styles.featureProductsWrapper}>
        <p className={styles.featureProductsText}>{data?.features?.info?.title}</p>
        <div className={styles.featureProductsLogoList}>
          {
            data?.features?.info?.images?.map((image,index)=>
              <a href={image?.link} key={index} className={styles.featureProductsLogoWrapper}>
                <img src={image?.url} alt={image?.altText} className={styles.featureProductsLogo} loading="lazy"></img>
              </a>
            )
          }
        </div>
        <a  href={'#mktoForm'} className={styles.heroBtn1Wrapper}>
              <span className={styles.heroBtn1Text}>{data?.features?.info?.link?.text}</span>
              <div className={styles.heroBtn1}>
                <img className={styles.heroBtn1Img} src={Dot}></img>
              </div>
        </a>
      </div>
    </div>
  )
}

const FullWidthBanner = () => {
  return (
    <div className={styles.fullWidthBannerContainer}>
      <div className={styles.fullWidthBannerWrapper}>
        <div className={styles.fullWidthBannerLogoWrapper}>
          <div className={styles.fullWidthBannerLogo}>
            <img className={styles.fullWidthBannerImage} src={data?.fullWidthBanner?.image?.url} 
              alt={data?.fullWidthBanner?.image?.altText} loading="lazy"></img>
          </div>
        </div>
        <div className={styles.fullWidthBannerTextWrapper}>
          <p className={styles.fullWidthBannerTitle}>
            {data?.fullWidthBanner?.title}
          </p>
          <p className={styles.fullWidthBannerDesc}>{data?.fullWidthBanner?.description}</p>
          <div>
            <p className={styles.fullWidthBannerName}>— {data?.fullWidthBanner?.name}</p>
            <p className={styles.fullWidthBannerDesc}>{data?.fullWidthBanner?.position} { data?.fullWidthBanner?.companyName ? ", " + data?.fullWidthBanner?.companyName  : ""}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

const LogoSlider = () => {
  return (
    <div className={styles.logoSliderContainer}>
      <div className={styles.logoStrip}>
      { data?.logoSlider?.logoList?.map((logo, index)=>
        <img key={index} className={styles.logoSliderImage + " tw-max-w-[100px]"} src={logo?.url} alt={logo?.altText}></img>
      )
      }
      </div>
    </div>
  )
}

const TwoColComp = () => {
  return (
    <div className={styles.twoColCompContainer}>
      <div className={styles.twoColCompLeftWrapper}>
        <div className={styles.twoColCompTextWrapper}>
          <h2 className={styles.twoColCompTitle}>
              {data?.twoColComp?.title}
          </h2>
          <p className={styles.twoColCompDesc}>
              {data?.twoColComp?.description}
          </p>
        </div>
        <a href={data?.twoColComp?.link?.url} className={styles.twoColCompLinkWrapper}>
          <span className={styles.twoColCompLinkText}>{data?.twoColComp?.link?.text}</span>
          <img src= {arrowRightBlue} alt='Right arrow'></img>
        </a>
      </div>
      <div className={styles.twoColCompRightWrapper}>
        {
          data?.twoColComp?.items?.map((item, index)=>
            <div key={index} className={styles.twoColCompCardWrapper}>
              <div className={styles.twoColCompCardTextWrapper}>
                <div className={styles.twoColCompCardLogoWrapper}>
                  <img src={item?.logo?.url} alt={item?.logo?.alt} className=" tw-min-h-[25px]"></img>
                </div>
                <h3 className={styles.twoColCompCardTitle}>{item?.title}</h3>
                <p className={styles.twoColCompCardDesc}>{item?.description}</p>
              </div>
              <a href={item?.link?.url} className={styles.twoColCompCardLink}>
                <span className={styles.twoColCompCardLinkText}>{item?.link?.text}</span>
                <img src= {arrowRightBlue} alt='Right arrow'></img>
              </a>
            </div>
          )
        }
      </div>
    </div>
  )
}

const HeroBanner1 = () => {
  return <>
     <div className={styles.heroContainer1}>
        <div className={styles.leftWrapper}>
          <h2 className={styles.hero1Title}>
            {data?.heroBanner1?.title}
          </h2>
          <p className={styles.hero1Desc}>{data?.heroBanner1?.description}</p>
          <div className={styles.heroBtnWrapper}>
            <a href={'#mktoForm'} className={styles.heroBtn1Wrapper}>
              <span className={styles.heroBtn1Text}>{data?.heroBanner1?.link1?.text}</span>
              <div className={styles.heroBtn1}>
                <img className={styles.heroBtn1Img} src={Dot}></img>
              </div>
            </a>
            <a href={data?.heroBanner1?.link2?.url} className={styles.heroBtn2Wrapper}>
              <span className={styles.heroBtn2Text}>{data?.heroBanner1?.link2?.text}</span>
              <img src={arrowRight}></img>
            </a>
        </div>
        </div>
        <div>
          <img className={styles.heroImage} src={data?.heroBanner1?.image?.url} alt={data?.heroBanner1?.image?.alt} loading="lazy"></img>
        </div>
     </div>
  </>
}

const Services = () => {
  return (
    <div className={styles.serviceContainer}>
      <div className={styles.serviceHeadWrapper}>
        <h2 className={styles.serviceTitle}>{data?.services?.title}</h2>
        <p className={styles.serviceDesc}>{data?.services?.description}</p>
      </div>
      <div className={styles.serviceCardContainer}>
        {
          data?.services?.items?.map((item,index)=>
            <div key={index} className={styles.serviceCardWrapper}>
            <div className={styles.serviceCardTextWrapper}>
              <div className={styles.serviceCardLogoWrapper}>
                <img className={styles.serviceCardLogo} src={item?.logo?.url} alt={item?.logo?.alt}></img>
              </div>
              <div className={styles.serviceCardText}>
                <h3 className={styles.serviceCardTitle}>{item?.title}</h3>
                <p className={styles.serviceCardDesc}>{item?.description}</p>
              </div>
            </div>
            <a href={item?.link?.url} className={styles.serviceCardLinkWrapper}>
              <span className={styles.serviceCardLinkText}>{item?.link?.text}</span>
              <img src= {arrowRightBlue} alt='Right arrow'></img>
            </a>
          </div>
          )
        }
      </div>
    </div>
  )
}

const Resource = () => {
  return (
    <div className={styles.resourceContainer}>
      <div className={`${styles.resourceHeadingWrapper} container`}>
        <h2 className={styles.resourceTitle}>{data?.resources?.title}</h2>
        <a href={data?.resources?.link?.url} className={styles.resourceLinkWrapper}>
          <span className={styles.resourceLinkText}>{data?.resources?.link?.text}</span>
          <img src= {arrowRightBlue} alt='arrow button'></img>
        </a>
      </div>
      <div className={styles.resourceWrapper}>
        <div className={`${styles.resourceCardWrapper} container`}>
          <a href={data?.resources?.resourceList?.resource1?.link} className={styles.resourceCard}>
            <img className={styles.resourceCardImg} src={data?.resources?.resourceList?.resource1?.image?.url} alt={data?.resources?.resourceList?.resource1?.image?.altText} loading="lazy"/>
            <div className={styles.resourceCardTextWrapper}>
              <p className={styles.resourceCardTitle}>{data?.resources?.resourceList?.resource1?.title}</p>
              <p className={styles.serviceCardDesc}>{data?.resources?.resourceList?.resource1?.description}</p>
              <div className={styles.resourceCardBtnWrapper + " tw-static lg:tw-absolute tw-bottom-[30px]"}>
                <div className={styles.resourceCardBtn}>{data?.resources?.resourceList?.resource1?.button?.text}</div>
              </div>
            </div>
          </a>
          <a href={data?.resources?.resourceList?.resource2?.link} className={styles.resourceCard}>
            <img className={styles.resourceCardImg} src={data?.resources?.resourceList?.resource2?.image?.url} alt={data?.resources?.resourceList?.resource2?.image?.altText} loading="lazy"/>
            <div className={styles.resourceCardTextWrapper}>
              <p className={styles.resourceCardTitle}>{data?.resources?.resourceList?.resource2?.title}</p>
              <p className={styles.serviceCardDesc}>{data?.resources?.resourceList?.resource2?.description}</p>
              <div className={styles.resourceCardBtnWrapper + " tw-static lg:tw-absolute tw-bottom-[30px]"}>
                <div className={styles.resourceCardBtn}>{data?.resources?.resourceList?.resource2?.button?.text}</div>
              </div>
            </div>
          </a>
          <div className={styles.resourceMultiCardWrapper}>
            {
              data?.resources?.resourceList?.list?.map((item, index) =>
                <a key={index} href={item?.link} className={styles.resourceMultiCard}>
                  <div>
                    <img className={styles.resourceMultiCardImg} src={item?.image?.url} alt={item?.image?.altText}></img>
                    <div className={styles.resourceCardBtnWrapper + " md:!tw-hidden !tw-flex tw-mt-[15px] tw-w-fit"}>
                      <div className={styles.resourceCardBtn}>{item?.button?.text}</div>
                    </div>
                  </div>
                  <div className={styles.resourceMultiCardTextWrapper}>
                    <p className={styles.resourceMultiCardText}>{item?.title}</p>
                    <div className={styles.resourceCardBtnWrapper + " md:!tw-flex !tw-hidden"}>
                      <div className={styles.resourceCardBtn}>{item?.button?.text}</div>
                    </div>
                  </div>
                </a>
              )
            }
          </div>
        </div>
      </div>
      <div className={styles.walkthroughWrapper}>
        <h6 className={styles.walkthroughTitle}>{data?.resources?.walkthrough?.title}</h6>
        <div className={styles.walkthroughFlexWrapper}>
          <VideoGrid />
        </div>
      </div>
    </div>
  )
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0',
    border: 'none',
    borderRadius: '0px',
    overflow: 'hidden'
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)'
  }
};

const closeButtonStyles = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  background: 'rgba(0, 0, 0, 0.5)',
  border: 'none',
  fontSize: '20px',
  cursor: 'pointer',
  color: '#fff',
  zIndex: '1000',
  padding: '5px',
};

const VideoModal = ({ isOpen, onRequestClose, wistiaId }) => (
  <Modal isOpen={isOpen} onRequestClose={onRequestClose} style={customStyles}>
    <div>
      <button onClick={onRequestClose} style={closeButtonStyles}>x</button>
      <div
        className={`wistia_embed wistia_async_${wistiaId} videoFoam=true`}
        style={{height:'360px', width:'640px'}}
      >
        &nbsp;
      </div>
    </div>
  </Modal>
);

const VideoGrid = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);

  const openModal = (wistiaId) => {
    setCurrentVideo(wistiaId);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentVideo(null);
  };

  return (
    <>
      {data?.resources?.walkthrough?.items?.map((video, index) =>
        <div className={styles.walkthroughCardWrapper} onClick={() => openModal(video.wistiaId)}>
          <img src={video?.logo?.url} alt={video?.logo?.altText}></img>
          <div className={styles.walkthroughCardText}>
            <p className={styles.walkthroughCardTitle}>{video?.title}</p>
            <p className={styles.walkthroughCardDesc}>{video?.description}</p>
          </div>
        </div>
      )
      }
      <VideoModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        wistiaId={currentVideo}
      />
    </>
  );
};

export default function DeskRoomBookingSoftware() {
  const heroImages = useHeroImages();
  const pageDataPath = getPageDataJsonPath('/solutions/desk-room-booking-software')
  const formImages = useFormImages()
  const contentEntity = {
    "uuid": "abfd837b-6954-4cb8-a9c1-9c100cccda3b",
    "nid": 4046,
    "fieldEmbedCode": "<script src=\"//app-abc.marketo.com/js/forms2/js/forms2.min.js\"></script>\r\n<form id=\"mktoForm_1693\"></form>\r\n<script>MktoForms2.loadForm(\"//app-abc.marketo.com\", \"167-BOY-362\", 1693, function(form) {\r\n    dataLayer.push({\r\n      'event': 'gaTriggerEvent',\r\n      'gaCategory': 'CTA Engagement',\r\n      'gaAction': 'Form Load',\r\n      'gaLabel': 'Demo Request',\r\n    });\r\n    form.onSuccess(function(values, followUpUrl) {\r\n      dataLayer.push({\r\n        'event': 'gaTriggerEvent',\r\n        'gaCategory': 'CTA Engagement',\r\n        'gaAction': 'Form Submission',\r\n        'gaLabel': 'Demo Request',\r\n      });\r\n    });\r\n  });\r\n</script>",
    "title": "Request A Demo ",
    "fieldFormHeader": "Request A Demo",
    "fieldHideTitle": false,
    "fieldShowTitleFullWidth": null,
    "fieldFormText": null,
    "fieldFormImage": {
        "alt": "Form Image"
    },
    "relationships": {
        "fieldFormImage": {
        },
        "fieldFormImageWithCover": formImages['staticPages/form-face.png'],
        "fieldMediaImage": {
            "fieldImage": {
                "alt": "bg"
            },
            "relationships": {
                "field_image": formImages['staticPages/form-bg.jpg'],
            }
        }
    },
    "type": {
        "id": "marketo_forms"
    },
    "fieldVersions": "advanced",
    "fieldCtaUrl": null
  }
    return (
      <>
        <SEO
          meta={
            [
              { 'name': 'title', 'content': 'Best Meeting Room and Desk Booking Software | Accruent' },
              { 'name': 'description', 'content': "Seamlessly manage spaces with the best meeting room and desk booking software. Streamline room scheduling and reservations for a smooth office experience." }
            ]
          }
          heroImage={heroImages['staticPages/desk-hero.jpg']}
          preloads= {[pageDataPath]}
          lang="en"
          pageUrl="/solutions/desk-room-booking-software"
          alternatives={[
            {
                "href": "/solutions/desk-room-booking-software",
                "hreflang": "en"
            },
            {
                "href": "/uk/solutions/desk-room-booking-software",
                "hreflang": "en-gb"
            },
            {
                "href": "/solutions/desk-room-booking-software",
                "hreflang": "x-default"
            }
        ]}
        />
          <HeroBanner heroImages={heroImages}/>
          <Features/>
          <FullWidthBanner/>
          <LogoSlider/>
          <TwoColComp/>
          <HeroBanner1/>
          {/* <Services/> */}
          <Resource/>
          <div id="mktoForm">
            <ContentMarketoForm
              entity={contentEntity}
              locale="en"
              pageTitle="Desk and Room Booking: Best Meeting Room and Desk Booking Software"
              formFullWidth={false}
            />
          </div>
          <Support/>
      </>
    );
  }
  